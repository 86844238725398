<template>
  <section class="lightcar-section">
    <div class="container">
      <div class="lightcar-section__inner">
        <div class="lightcar-section__image">
          <img
            src="@/assets/images/boats.jpg"
            style="width: 100%; height: 100%; object-fit: cover;"
            class="about-img"
            alt="heavycar-image"
          >
        </div>
        <div class="lightcar-section__info">
          <div class="lightcar-section__title main__title">
            Технический осмотр плавсредства
          </div>
          <div class="lightcar-section__text main__text">
            Компания «Технологический Экспертный Центр» обеспечивает технический надзор водного
            транспорта для судоводителей и владельцев моторных лодок, яхт, водных мотоциклов,
            катеров, включая иные плавательные средства. Водный транспорт вы уже присвоили и даже
            можете воспользоваться им по назначению, однако теперь нужно удостовериться, что судно
            пригодно для использования.
          </div>
          <p class="medium__text price-description">
            от 4000 грн
          </p>
          <button
            class="btn btn--black"
            @click="openModal()"
          >
            Заказать полную оценку
          </button>
        </div>
      </div>
    </div>
    <pop-up />
  </section>
</template>

<script>
export default {
    name: 'BoatsDescr',
    components: {
    PopUp: () => import('@/components/PopUp')
    },
    methods: {
    openModal() {
        document.querySelector('.popUp').classList.add('popUp__active')
        document.querySelector('body').classList.add('lock')
    },
    closeModal() {
        document.querySelector('.popUp').classList.remove('popUp__active')
    }
    }
}
</script>
